jQuery(document).ready(function($){

	// NOT POSSIBLE TO ACHIEVE
	// $('.hero-homepage__slick').on('init', function(event, slick){
	// 	$(".slick-dots li").each(function() {
	// 	    $(this).attr("role", "TEST");
	// 	});
	// });


	// SLICK SLIDER
	if( $('.hero-homepage__slide').length > 1 ) {


		// load video for active slide on init
		$('.hero-homepage__slick').on('init', function(event, slick){
			load_hp_hero_video( $('.hero-homepage__slick .slick-current.slick-active') );
			window.dispatchEvent(new Event('resize'));
		});

		// only load video when beforeChange is triggered
		$('.hero-homepage__slick').on('beforeChange', function(event, slick, currentSlide, nextSlide){
			load_hp_hero_video( $('.hero-homepage__slick [data-slick-index="' + nextSlide + '"]') );
			window.dispatchEvent(new Event('resize'));
		});

		$('.hero-homepage__slick').on('afterChange', function(event, slick, currentSlide, nextSlide){
		    window.dispatchEvent(new Event('resize'));
		});

		$('.hero-homepage__slick').slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			dots: true,
			arrows: false,
			infinite: true,
			// vertical: true,
			speed: 750,
			autoplay: true,
			// autoplaySpeed: 5000,
			autoplaySpeed: parseInt( $(".hero-homepage__slick").attr("data-slide-speed") ),
			pauseOnHover: false,
			pauseOnFocus: false,
			fade: true,
			cssEase: 'ease',
			// lazyLoad: 'ondemand',
			swipe: false,
    		touchMove: false,
    		appendDots: $('.hero-homepage__dots'),
		});

	} else {
		load_hp_hero_video( $('.hero-homepage__slide') );
	}

	function load_hp_hero_video( $slide ) {

		if( $slide.length > 0 ) {

			if( $slide.find('.hero-homepage__background-video').length > 0 ) {

				$video_container = $slide.find('.hero-homepage__background-video');

				if( $video_container.data('src') ) {

					// create video elemend
					var video =document.createElement('video');
					video.src = $video_container.data('src');
					video.autoplay = true;
					video.controls = false;
					video.muted = true;
					video.loop = true;

					// fix for iOS
					video.setAttribute('playsinline', 'playsinline');

					// insert video element
					$video_container.html('');
					$video_container.get(0).append(video);
					$video_container.data('src', '');

					// play video element
					$video_container.find('video').get(0).play();

				}
			}
		}
	}
});